<template>
  <div
    class="forensics"
    v-if="initShow"
    v-loading="fullscreenLoading"
    element-loading-text="加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.6)"
  >
    <div class="platecont">
      <div class="title-text">请输入您的车牌号码</div>
      <div class="plate-box">
        <div class="input-plate-wrap">
          <div class="plate-for" v-for="(item, index) in keyBoard.kbLenght" :key="index" @click="getmenuItem(index)">
            <div
              class="plate-item"
              v-if="index != keyBoard.kbLenght - 1"
              :class="{ active: index === keyBoard.indexNum }"
              @click="carPlateInput(index)"
            >
              <i v-if="keyBoard.value[index] == ''" style="color: #a3a3a3; font-size: 18px"> - </i>
              {{ keyBoard.value[index] || "" }}
            </div>
            <!-- 新能源多一位车牌 -->
            <div
              class="plate-item-new"
              v-if="index == keyBoard.kbLenght - 1"
              :class="{ active: index === keyBoard.indexNum }"
              @click="carPlateInput(index)"
            >
              <i
                v-if="keyBoard.value[keyBoard.kbLenght - 1] == '' && keyBoard.value.length == 8"
                style="color: #00deb9; font-size: 12px; writing-mode: vertical-rl"
              >
                新能源
              </i>
              {{ keyBoard.value[index] }}
            </div>
          </div>
        </div>
      </div>
      <button class="submit" type="primary" @click="onSubmit">确 认</button>
      <!-- <div class="footer-text">
        <span>
          Copyright © 深圳市中智车联科技有限责任公司 All Rights Reserved.
        </span>
      </div> -->
      <div>
        <PlateInput
          :kbShow="keyBoard.isShow"
          :kbIndex="keyBoard.indexNum"
          :kbType="keyBoard.length"
          @childValue="keyboardValue"
          @childClose="keyboardClose"
          @childIndex="keyboardIndex"
          @childDel="keyboardDel"
        />
      </div>
    </div>
  </div>
</template>

<script>
import mqtt from "mqtt";
import { uuid } from "vue-uuid";
import PlateInput from "./components/plateInput";
import { Dialog } from "vant";

export default {
  name: "ParkNo",
  components: {
    PlateInput,
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      fullscreenLoading: false,
      initShow: true,
      keyBoard: {
        //键盘
        isShow: false,
        kbLenght: 8, // 车牌输入框长度
        indexNum: " ", // 点击车牌设置焦点的下标
        value: ["", "", "", "", "", "", "", ""], // 键盘值
        berthId: null, // 泊位id
        roadId: null, // 路段id
      },
      // 黑盒连接参数
      client: {},
      msg: {},
      // 发布的主题
      mtopic: "tcp/" + localStorage.getItem("parkCode"),
      // 订阅的主题
      dtopic: "tpc/" + localStorage.getItem("parkCode"),
      uuid: uuid.v1(),
      promiseNext: false,
      queryPlateNo: null,
      recordNo: null,
      payScene: null,
      domain: process.env.VUE_APP_MQTT,
    };
  },
  created() {
    this.connect();
  },
  mounted() {
    this.queryPlateNo = this.$route.query.plateNo;
    this.recordNo = this.$route.query.recordNo;
    this.payScene = this.$route.query.payScene;
  },
  destroyed() {},
  watch: {
    promiseNext: {
      handler(val) {
        if (val) {
          let { msg } = this;
          let orderDate = JSON.stringify(msg);
          this.$router.push({
            path: "/outpark/parklotcharging",
            query: { orderDate },
          });
        }
      },
    },
    queryPlateNo: {
      handler(val) {
        if (val) {
          localStorage.setItem("plateNo", val);
          this.initShow = false;
          let { uuid, mtopic, dtopic, recordNo, payScene } = this;
          let obj = {
            code: 1,
            dataItems: [
              {
                payScene,
                plateNumber: val,
                outChannelid: localStorage.getItem("channelId"),
                recordNo,
              },
            ],
            parkCode: localStorage.getItem("parkCode"),
            serviceId: "pay.queryfeebyplate",
            uuid,
          };
          //订阅的主题名称
          this.client.subscribe([dtopic], { qos: 1 }, (err) => {
            if (!err) {
              console.log("订阅成功");
              // 发布主题
              this.publish(mtopic, obj);
            } else {
              console.log("消息订阅失败！");
            }
          });
        }
      },
    },
  },
  methods: {
    // 去缴费
    onSubmit() {
      this.fullscreenLoading = true;
      let { keyBoard, uuid, mtopic, dtopic } = this;
      let plateNo = "";
      let plateNoList = [];
      // 判断车牌号数组是否为空与需要满足7位数或8位数，用数组进行后续的判断
      if (keyBoard.value[6] !== "") {
        plateNoList = keyBoard.value.filter((item) => {
          plateNo += item;
          return item !== "";
        });
      }
      localStorage.setItem("plateNo", plateNo);
      if (plateNoList.length == 7 || plateNoList.length == 8) {
        let obj = {
          code: 1,
          dataItems: [
            {
              payScene: 0,
              plateNumber: plateNo,
            },
          ],
          parkCode: localStorage.getItem("parkCode"),
          serviceId: "pay.queryfeebyplate",
          uuid,
        };
        //订阅的主题名称
        this.client.subscribe([dtopic], { qos: 1 }, (err) => {
          if (!err) {
            console.log("订阅成功");
            // 发布主题
            this.publish(mtopic, obj);
          } else {
            console.log("消息订阅失败！" + err);
          }
        });
      } else {
        this.fullscreenLoading = false;
        Dialog.alert({
          message: "请先填写车牌号",
          theme: "round-button",
        }).then(() => {
          // on close
        });
      }
    },
    //点击车牌输入框
    carPlateInput(res) {
      this.keyBoard.isShow = true; //显示键盘
      this.keyBoard.indexNum = res; //设置键盘焦点下标
    },
    //键盘点击返回的值
    keyboardValue(res) {
      this.keyBoard.value.splice(this.keyBoard.indexNum, 1, res);
      // this.$setObject(this.keyBoard.value, this.keyBoard.indexNum, res);
    },
    //删除键盘值
    keyboardDel(res) {
      this.keyBoard.value.splice(res, 1, "");
      // this.$setObject(this.keyBoard.value, res, "");
    },
    //关闭
    keyboardClose(res) {
      this.keyBoard.isShow = res;
      this.keyBoard.indexNum = ""; //关闭键盘键盘高亮取消
    },
    //车牌焦点下标
    keyboardIndex(res) {
      this.keyBoard.indexNum = res;
    },
    // 车牌下标展示
    getmenuItem(index) {},
    // 黑盒连接
    connect() {
      let options = {
        // username: "zhongzhiiov",
        // password: "Zhongzhiiov@2022",
        username: "union_zzcl",
        password: "union2021",
        cleanSession: false,
        keepAlive: 60,
        clientId: "mqttjs_" + Math.random().toString(16).substr(2, 8),
        connectTimeout: 4000,
      };
      this.client = mqtt.connect(`${this.domain}`, options);
      this.client.on("connect", (e) => {
        console.log("成功连接服务器:", e);
      });
      //重新连接
      this.reconnect();
      //是否已经断开连接
      this.mqttError();
    },
    //发布消息@topic主题  @message发布内容
    publish(topic, message) {
      if (!this.client.connected) {
        console.log("客户端未连接");
        return;
      }
      // alert(JSON.stringify(message));
      this.client.publish(topic, JSON.stringify(message), { qos: 1 }, (err) => {
        if (!err) {
          console.log("主题为" + topic + "发布成功");
          //监听获取信息
          this.getMessage();
        }
      });
    },
    //监听接收消息
    getMessage() {
      let { uuid, recordNo } = this;
      // 控制因信息发送相同多次导致多次弹框，所以控制一次进行
      let oneEdit = true;
      this.client.on("message", (topic, message) => {
        // 判断是否有信息
        if (message) {
          // console.log("收到来着", topic, "的信息", message.toString());
          let res = JSON.parse(message.toString());
          if (res.dataItems.length) {
            sessionStorage.setItem("recordNo", res.dataItems[0].recordNo);
          }
          // 判断uuid是否相同 recordNo == res.dataItems[0].recordNo
          if (uuid == res.uuid) {
            if (oneEdit) {
              oneEdit = false;
              if (res.code == 1) {
                // alert("code=1");
                this.msg = res;
                this.promiseNext = true;
                this.fullscreenLoading = false;
              } else if (res.code == 0) {
                this.fullscreenLoading = false;
                if (res.dataItems.length) {
                  if (res.dataItems[0].carType == 7) {
                    Dialog.alert({
                      message: "您是储值卡用户车辆，可以直接离场",
                      theme: "round-button",
                    }).then(() => {
                      // on close
                    });
                  }
                } else {
                  Dialog.alert({
                    message: `${res.msg}`,
                    theme: "round-button",
                  }).then(() => {
                    // on close
                  });
                }
              } else {
                this.fullscreenLoading = false;
                Dialog.alert({
                  message: `${res.msg}`,
                  theme: "round-button",
                }).then(() => {
                  // on close
                });
              }
              this.unsubscribe();
            }
          } else {
            Dialog.alert({
              message: `${res.msg}`,
              theme: "round-button",
            }).then(() => {
              // on close
            });
          }
          // switch (topic) {
          //   case dtopic:
          //     this.msg = res;
          //     break;
          //   default:
          //     return;
          //     this.msg = res;
          // }
        }
      });
    },
    //
    mqttError() {
      this.client.on("error", (error) => {
        console.log("连接失败：", error);
        this.client.end();
      });
    },
    //取消订阅
    unsubscribe() {
      let { dtopic } = this;
      this.client.unsubscribe(dtopic, (error) => {
        console.log("主题为" + dtopic + "取消订阅成功", error);
      });
    },
    //断开连接
    unconnect() {
      this.client.end();
      this.client = null;
      console.log("服务器已断开连接！");
    },
    //监听服务器重新连接
    reconnect() {
      this.client.on("reconnect", (error) => {
        console.log("正在重连:", error);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
html,
body {
  width: 100%;
  height: 100%;
}
.forensics {
  width: 100%;
  height: 100%;
  position: absolute;

  .platecont {
    width: 100%;
    height: 100%;

    .title-text {
      margin: 20px 0 18px 15px;
      width: 100%;
      height: 36px;
      line-height: 36px;
      font-size: 25px;
      white-space: nowrap;
    }

    .plate-box {
      position: relative;
      padding: 0 15px;
      width: 342px;

      .input-plate-wrap {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .plate-for {
          position: relative;

          .plate-item {
            box-sizing: border-box;
            position: relative;
            height: 50px;
            line-height: 50px;
            width: 36px;
            font-size: 18px;
            text-align: center;
            box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.08);
            border-radius: 5px;
            background-color: #ffffff;

            // &.active {
            //   border: 1px dashed #bbe6fe;
            // }
          }

          .plate-item-new {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 36px;
            height: 50px;
            box-sizing: border-box;
            background-color: rgba(0, 222, 185, 0.1);
            border: 1px dashed #00bc9d;
            border-radius: 5px;
            font-size: 18px;
          }
        }

        .point {
          display: flex;
          width: 14px;
          height: 14px;
          border-radius: 14px;
          margin-top: 40px;
          background-color: #c3c2cb;
        }

        .icondiandongche {
          color: green;
          font-size: 20px;
        }
      }
    }

    .plateColor {
      display: flex;
      align-items: center;
      margin-top: 20px;

      .plateColor-item {
        margin: 0 5px;
        width: 60px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        font-size: 18px;
        background-color: #fff;
        box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.05);
        border-radius: 5px;
      }
    }

    .submit {
      display: block;
      outline: none;
      border: none;
      margin: 22px auto;
      width: 345px;
      height: 44px;
      line-height: 44px;
      text-align: center;
      color: #fff;
      font-size: 16px;
      background-color: rgb(35, 112, 255);
      /* 蓝色阴影 */
      box-shadow: 0px 2px 10px rgba(35, 112, 255, 0.4);
      border-radius: 22px;
    }
  }
}
</style>
